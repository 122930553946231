@tailwind base;
@tailwind components;
@tailwind utilities;
.roboto {
  font-family: "Roboto Condensed", sans-serif;
}

.lato {
  font-family: "Lato", sans-serif;
}
.week-font {
  color: #0d249b;
  font-family: "Pushster", cursive;
  font-weight: 600;
  font-size: 3rem;
}
.cursive {
  font-family: "Dancing Script", cursive;
  font-size: 2rem;
}
#testimonial {
  min-height: 220px;
}

.aspect-ratio-box {
  height: 0;
  padding-top: 56.25%;
  /* background:black; */
}

.vid-controls {
  background: white;
  /* position: fixed; */
  /* right: 40%; */
  /* bottom: 1%; */
  height: 70px;
  width: 800px;
}

.cam-controls {
  padding-top: -113px;
}

.speech-bubble {
  position: relative;
  background: #e8e8e8;
  border-radius: 0.4em;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #e8e8e8;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}

.campopover {
  position: absolute;
  bottom: 0;
  height: 113px;
  width: 200px;
  background-color: #e0e0e080;
  z-index: 1;
}

.camera:before {
  content: "\01F4F9";
}

.palette:after {
  content: "\01F3A8";
  color: transparent;
  text-shadow: 0 0 0 #4f5768;
}

.logo:after {
  content: "\0023E3";
  color: transparent;
  text-shadow: 0 0 0 #4f5768;
}

.CrimsonTide {
  background: url("/images/gradients/CrimsonTide.jpg");
}

.coolbg {
  background-image: url("/images/coolbg.svg");
  background-size: cover;
}

.featured:after {
  content: "\002763";
  color: transparent;
  text-shadow: 0 0 0 #1b50c1;
}

.instructor:after {
  color: #1b50c1;
  content: "\01F481";
  /* text-shadow: 0 0 0 #1b50c1; */
}

/* .group:after {
  content: "\01F465";
  color: #1b50c1;
} */

@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
  .text-shadow-md {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.25);
  }
  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }
  .text-shadow-none {
    text-shadow: none;
  }
}

.login-text {
  font-size: 33.3vh;
  color: white;
  opacity: 0.5;
  line-height: 80%;
}

.register-bg {
  background-color: #fff;
  background-image: url("/images/always.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

.ember-modal-dialog {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px #222;
  padding: 10px;
}

.ember-modal-overlay.translucent {
  background-color: rgba(128, 128, 128, 0.77);
}

.ember-modal-dialog {
  z-index: 51;
  position: fixed;
}

.ember-modal-dialog.emd-in-place {
  position: static;
}

.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center
  .ember-modal-dialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ember-modal-wrapper.emd-animatable.emd-wrapper-target-attachment-center {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ember-modal-wrapper.emd-animatable.emd-wrapper-target-attachment-center
  .ember-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ember-modal-wrapper.emd-animatable .ember-modal-dialog {
  position: relative;
}

.ember-modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}

.spinner-border {
  vertical-align: -0.125em;
  border: 0.25em solid;
  border-right-color: transparent;
}

.custom-styles-modal-container {
  color: rgb(55, 65, 81);
  margin-left: -25px;
  margin-top: 10px;
  width: 40%;
}
